import React from "react";

import Logo from "./icons/logo";
import LogoLarge from "./icons/logo-large";
import Twitter from "./icons/twitter";
import Instagram from "./icons/instagram";
import Facebook from "./icons/facebook";

const ICONS = {
  logo: Logo,
  "logo-large": LogoLarge,
  twitter: Twitter,
  instagram: Instagram,
  facebook: Facebook,
};

export default ({ name, ...rest }) => {
  const icon = ICONS[name];
  if (icon) {
    return icon(rest);
  } else {
    return <div>?</div>;
  }
};
