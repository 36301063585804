import * as React from "react";

function SvgLogoLarge({ color = "#000", ...rest }) {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" {...rest}>
      <g fill={color} fillRule="nonzero">
        <path d="M27.715 59.848c-4.113-.375-7.793-1.407-11.356-3.176-2.976-1.477-5.015-2.942-8.18-5.86C3.927 46.876 2.099 43.7.833 38.028c-.703-3.14-.82-4.23-.82-7.886-.012-3.797.117-4.899.925-7.852C3.364 13.465 9.892 5.848 18 2.391c8.121-3.47 17.684-3.141 25.664.867 7.723 3.867 13.453 11.285 15.516 20.074.597 2.566.703 3.574.703 6.809 0 2.402-.047 3.316-.211 4.336-.516 3.105-1.383 5.789-2.79 8.601-4.089 8.25-11.706 14.32-20.273 16.16-2.66.575-6.422.832-8.894.61zm3.633-3.106c4.617-.68 9-2.46 12.609-5.12 1.523-1.114 4.16-3.75 5.11-5.075 2.519-3.55 3.75-7.77 3.925-13.477.246-7.945-2.215-15.586-6.89-21.386-1.008-1.254-3.059-3.317-4.207-4.23-1.922-1.548-4.97-3.259-7.266-4.09C26.977.562 18.563 1.98 11.766 7.183c-4.348 3.328-7.793 8.378-9.645 14.074-1.5 4.676-1.863 9.738-.996 14.039 1.676 8.297 7.277 15.55 14.813 19.172 2.484 1.195 5.355 2.039 7.91 2.32.609.07 1.277.152 1.465.176.738.105 4.863-.047 6.035-.223z" />
        <path d="M24.68 50.93c-1.524-.258-3.035-1.442-3.762-2.93-.41-.855-.574-.984-1.23-.984-.446 0-.47-.024-.47-.352 0-.785 1.5-.996 2.298-.328.199.176.656.68 1.007 1.125.88 1.09 1.336 1.3 2.72 1.23.831-.046 1.382-.175 3.21-.785 1.219-.41 2.238-.703 2.274-.668.093.094-.622 1.242-1.184 1.899-1.172 1.37-3.117 2.086-4.863 1.793zM31.406 47.04c-.41-.235-.668-.528-1.383-1.536-.234-.34-.503-.387-.773-.14-.305.269-1.324.738-1.793.82-.973.175-1.922-.387-2.45-1.418-.362-.727-.679-1.09-.948-1.09-.118 0-.692.281-1.278.633-1.793 1.09-3.105 1.37-3.914.843-.316-.21-.398-.691-.14-.855.07-.047.41-.094.75-.094.55 0 .703-.07 1.933-.902 1.828-1.23 2.004-1.313 2.918-1.324 1.02 0 1.5.316 2.086 1.347.54.961.797 1.02 1.594.41.762-.574 1.289-.785 2.05-.785.844-.011 1.536.34 2.016 1.02.211.293.469.527.574.527.118 0 .82-.375 1.57-.832.75-.457 1.384-.82 1.407-.785.105.094-.293 1.312-.68 2.121-.515 1.066-1.043 1.781-1.523 2.074-.527.328-1.406.305-2.016-.035zM26.895 37.816c-1.02-.246-2.415-1.043-2.766-1.582-.2-.316-.14-.703.14-.808.094-.035.352.011.575.094 1.136.48 2.156.597 5.062.597 3.188 0 3.106-.047 1.864.82-.997.68-2.051.997-3.352.985-.61-.012-1.3-.059-1.523-.106zM24.152 34.395c-.035-.083-.457-2.333-.949-5.004-.492-2.66-1.031-5.309-1.183-5.883-1.008-3.774-3.575-5.602-7.254-5.145-.575.07-2.004.364-3.153.645-3.492.867-4.84.949-4.734.27.082-.598 4.547-2.708 7.16-3.387 1.36-.364 3.621-.434 4.816-.176 2.614.586 4.922 2.672 6.176 5.566 1.512 3.516 1.7 7.7.492 10.805-.34.867-1.16 2.45-1.277 2.45-.023 0-.07-.071-.094-.141zM38.496 26.625c-1.5-.41-4.02-1.969-4.172-2.59-.07-.27.106-.633.317-.633.058 0 .492.258.984.575.492.328 1.324.761 1.852.972.843.328 1.101.375 2.074.387 1.043 0 1.172-.023 2.11-.457 2.015-.914 2.39-1.043 3.046-1.055.621-.012.645 0 .61.281-.094.832-1.149 1.676-2.813 2.262-1.242.434-2.941.54-4.008.258z" />
        <path d="M11.332 26.332c-1.746-.469-3.059-1.57-3.48-2.918-.082-.27-.07-.281.445-.223.305.036 1.172.364 1.98.739 2.438 1.148 2.883 1.265 4.313 1.195 1.336-.059 2.027-.258 3.41-.96.89-.458 1.172-.505 1.395-.235.128.152.105.234-.13.48-.503.54-1.898 1.313-3.035 1.7-.96.328-1.3.374-2.578.421-1.195.024-1.629-.011-2.32-.199zM46.746 20.988c-.316-.164-.926-.586-1.348-.925-1.136-.915-1.77-1.29-2.859-1.688-3.305-1.195-6.633-.879-9.047.844-.55.398-.855.363-.855-.106 0-.504.34-1.066 1.066-1.746 1.805-1.664 4.57-2.472 7.383-2.168 2.707.305 5.11 1.23 6.164 2.39.352.376.68.88.785 1.196.188.55.258 1.957.117 2.32-.105.282-.75.235-1.406-.117z" />
      </g>
    </svg>
  );
}

export default SvgLogoLarge;
