export { wrapPageElement } from "./src";

const addScript = (url, selector) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;

  if (selector) {
    const el = document.querySelector(selector);
    if (el) {
      el.appendChild(script);
    }
  } else {
    document.body.appendChild(script);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname.includes("/book")) {
    addScript(
      "https://square.site/appointments/buyer/widget/sy59jdvocadzpc/LSNQAV0M4RET7.js",
      "#square-embed"
    );
  }
};
