import * as React from "react";

function SvgFacebook({ color = "#000", ...rest }) {
  return (
    <svg width="10" height="18" viewBox="0 0 10 18" {...rest}>
      <path
        d="M10 3.128H7.144c-.338 0-.714.444-.714 1.038V6.23H10v2.94H6.43V18H3.058V9.17H0V6.23h3.058V4.5C3.058 2.018 4.78 0 7.144 0H10v3.128"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgFacebook;
