import React from "react";
import { Flex, Box, Link as ThemedLink } from "theme-ui";
import { useLocation } from "@reach/router";
import Icon from "./icon";
import ScrollLink from "./scroll-link";
import Link from "./link";
import useBreakpointIndex from "./use-breakpoint-index";

const CustomScrollLink = ({ activePathname, to, ...rest }) => {
  const location = useLocation();

  if (location.pathname === activePathname) {
    return (
      <ScrollLink
        offset={-30}
        spy={true}
        smooth={true}
        duration={300}
        to={to}
        {...rest}
      />
    );
  } else {
    return <Link to={activePathname} {...rest} />;
  }
};

export default () => {
  const breakpoint = useBreakpointIndex();

  const renderNavigation = () => (
    <>
      <CustomScrollLink variant="nav" mx="20px" to="studio" activePathname="/">
        Studio
      </CustomScrollLink>
      <CustomScrollLink variant="nav" mx="20px" to="about" activePathname="/">
        About
      </CustomScrollLink>
      <CustomScrollLink variant="nav" mx="20px" to="contact" activePathname="/">
        Contact
      </CustomScrollLink>
      <Link variant="nav" mx="20px" to="/book">
        Book
      </Link>
    </>
  );

  const renderSocial = () => (
    <>
      <ThemedLink mx="15px" href="https://instagram.com/studio.lalune">
        <Icon name="instagram" />
      </ThemedLink>
      <ThemedLink mx="15px" href="https://twitter.com/studiolalune">
        <Icon name="twitter" />
      </ThemedLink>
    </>
  );

  return (
    <Flex px={[2, 2, 3]} py={[4, 3, 4]} sx={{ alignItems: "center" }}>
      {breakpoint >= 1 ? (
        <Box sx={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
          {renderNavigation()}
        </Box>
      ) : null}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
          justifyContent: ["center", "flex-end", "flex-end"],
        }}
      >
        {renderSocial()}
      </Box>
    </Flex>
  );
};
