import * as React from "react";

function SvgTwitter({ color = "#000", ...rest }) {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" {...rest}>
      <path
        d="M23 2.13a9.705 9.705 0 01-2.71.716A4.593 4.593 0 0022.365.332c-.912.52-1.923.9-2.997 1.103A4.797 4.797 0 0015.923 0c-2.605 0-4.718 2.034-4.718 4.545 0 .356.04.703.123 1.035C7.405 5.39 3.928 3.582 1.6.833a4.4 4.4 0 00-.638 2.285c0 1.574.833 2.965 2.098 3.782A4.866 4.866 0 01.922 6.33v.056c0 2.203 1.628 4.04 3.786 4.456a4.902 4.902 0 01-2.13.077c.6 1.805 2.342 3.12 4.407 3.156a9.69 9.69 0 01-5.86 1.945C.745 16.021.367 16 0 15.96A13.738 13.738 0 007.233 18c8.68 0 13.427-6.924 13.427-12.93 0-.195-.005-.392-.015-.587A9.366 9.366 0 0023 2.13"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgTwitter;
