import React from "react";
import { Link as ThemedLink, Flex, Box, Button } from "theme-ui";
import { useLocation } from "@reach/router";
import Icon from "./icon";
import ScrollLink from "./scroll-link";
import Link from "./link";
import useBreakpointIndex from "./use-breakpoint-index";

const CustomScrollLink = ({ activePathname, to, ...rest }) => {
  const location = useLocation();

  if (location.pathname === activePathname) {
    return (
      <ScrollLink
        offset={-30}
        spy={true}
        smooth={true}
        duration={300}
        to={to}
        {...rest}
      />
    );
  } else {
    return <Link to={activePathname} {...rest} />;
  }
};

const Navigation = ({ links, variant = "nav", ...rest }) =>
  links.map(([title, to], idx) => (
    <CustomScrollLink
      variant={variant}
      to={to}
      activePathname="/"
      key={`${idx}-${to}`}
      {...rest}
    >
      {title}
    </CustomScrollLink>
  ));

const Instagram = ({ color = "#000", ...rest }) => (
  <ThemedLink href="https://instagram.com/studio.lalune" {...rest}>
    <Icon color={color} name="instagram" />
  </ThemedLink>
);

const Twitter = ({ color = "#000", ...rest }) => (
  <ThemedLink href="https://twitter.com/studiolalune" {...rest}>
    <Icon color={color} name="twitter" />
  </ThemedLink>
);

export default () => {
  const index = useBreakpointIndex();
  return (
    <Flex px={3} py={3} sx={{ alignItems: "center" }}>
      <Box sx={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
        <CustomScrollLink mr="20px" to="home" activePathname="/">
          <Icon name="logo-large" />
        </CustomScrollLink>
        {index >= 1 ? (
          <>
            <Navigation
              mx="20px"
              links={[
                ["Studio", "studio"],
                ["About", "about"],
                ["Contact", "contact"],
              ]}
            />
            <Button as={Link} mx="20px" to="/book">
              Book
            </Button>
          </>
        ) : null}
      </Box>
      <Flex sx={{ alignItems: "center" }}>
        {index >= 1 ? (
          <>
            <Instagram mx="15px" />
            <Twitter mx="15px" />
          </>
        ) : (
          <>
            <Button as={Link} mr="15px" to="/book">
              Book
            </Button>
            <Instagram mx="15px" />
            <Twitter ml="15px" />
          </>
        )}
      </Flex>
    </Flex>
  );
};
