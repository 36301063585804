import * as React from "react";

function SvgLogo({ color = "#000", ...rest }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <path d="M18.477 39.898c-2.743-.25-5.196-.937-7.57-2.117-1.985-.984-3.345-1.96-5.454-3.906-2.836-2.625-4.055-4.742-4.898-8.523-.47-2.094-.547-2.82-.547-5.258-.008-2.532.078-3.266.617-5.235C2.242 8.977 6.594 3.9 12 1.594 17.414-.72 23.79-.5 29.11 2.172c5.148 2.578 8.968 7.523 10.343 13.383.399 1.71.469 2.383.469 4.539 0 1.601-.031 2.21-.14 2.89a19.497 19.497 0 01-1.86 5.735c-2.727 5.5-7.805 9.547-13.516 10.773-1.773.383-4.281.555-5.93.406zm2.421-2.07c3.079-.453 6-1.64 8.407-3.414 1.015-.742 2.773-2.5 3.406-3.383 1.68-2.367 2.5-5.18 2.617-8.984.164-5.297-1.476-10.39-4.594-14.258-.672-.836-2.039-2.21-2.804-2.82-1.282-1.032-3.313-2.172-4.844-2.727C17.984.375 12.375 1.32 7.844 4.79c-2.899 2.219-5.196 5.586-6.43 9.383-1 3.117-1.242 6.492-.664 9.36 1.117 5.53 4.852 10.366 9.875 12.78 1.656.797 3.57 1.36 5.273 1.547.407.047.852.102.977.118.492.07 3.242-.032 4.023-.149z" />
          <path d="M16.453 33.953c-1.015-.172-2.023-.96-2.508-1.953-.273-.57-.383-.656-.82-.656-.297 0-.313-.016-.313-.235 0-.523 1-.664 1.532-.218.133.117.437.453.672.75.586.726.89.867 1.812.82.555-.031.922-.117 2.14-.523.813-.274 1.493-.47 1.516-.446.063.063-.414.828-.789 1.266-.78.914-2.078 1.39-3.242 1.195zM20.938 31.36c-.274-.157-.446-.352-.922-1.024-.157-.227-.336-.258-.516-.094-.203.18-.883.492-1.195.547-.649.117-1.282-.258-1.633-.945-.242-.485-.453-.727-.633-.727-.078 0-.46.188-.852.422-1.195.727-2.07.914-2.609.563-.21-.141-.265-.461-.094-.57.047-.032.274-.063.5-.063.368 0 .47-.047 1.29-.602 1.218-.82 1.335-.875 1.945-.883.68 0 1 .211 1.39.899.36.64.532.68 1.063.273.508-.383.86-.523 1.367-.523a1.55 1.55 0 011.344.68c.14.195.312.351.383.351.078 0 .547-.25 1.047-.555.5-.304.921-.547.937-.523.07.062-.195.875-.453 1.414-.344.71-.695 1.188-1.016 1.383-.351.219-.937.203-1.343-.024zM17.93 25.21c-.68-.163-1.61-.694-1.844-1.054-.133-.21-.094-.468.094-.539.062-.023.234.008.383.063.757.32 1.437.398 3.375.398 2.125 0 2.07-.031 1.242.547-.664.453-1.367.664-2.235.656-.406-.008-.867-.039-1.015-.07zM16.102 22.93c-.024-.055-.305-1.555-.633-3.336-.328-1.774-.688-3.54-.79-3.922-.671-2.516-2.382-3.735-4.835-3.43-.383.047-1.336.242-2.102.43-2.328.578-3.226.633-3.156.18.055-.399 3.031-1.805 4.773-2.258.907-.242 2.414-.29 3.211-.117 1.742.39 3.282 1.78 4.117 3.71 1.008 2.344 1.133 5.133.329 7.204-.227.578-.774 1.632-.852 1.632-.016 0-.047-.046-.062-.093zM25.664 17.75c-1-.273-2.68-1.313-2.781-1.727-.047-.18.07-.421.21-.421.04 0 .329.171.657.382.328.22.883.508 1.234.649.563.219.735.25 1.383.258.695 0 .781-.016 1.406-.305 1.344-.61 1.594-.695 2.032-.703.414-.008.43 0 .406.187-.063.555-.766 1.117-1.875 1.508-.828.29-1.961.36-2.672.172z" />
          <path d="M7.555 17.555c-1.164-.313-2.04-1.047-2.32-1.946-.055-.18-.048-.187.296-.148.203.023.782.242 1.32.492 1.626.766 1.922.844 2.876.797.89-.04 1.351-.172 2.273-.64.594-.305.781-.337.93-.157.086.102.07.156-.086.32-.336.36-1.266.875-2.024 1.133-.64.219-.867.25-1.718.282-.797.015-1.086-.008-1.547-.133zM31.164 13.992c-.21-.11-.617-.39-.898-.617-.758-.61-1.18-.86-1.907-1.125-2.203-.797-4.422-.586-6.03.563-.368.265-.571.242-.571-.07 0-.337.226-.712.71-1.165 1.204-1.11 3.048-1.648 4.923-1.445 1.804.203 3.406.82 4.109 1.594.234.25.453.585.523.796.125.368.172 1.305.079 1.547-.07.188-.5.157-.938-.078z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogo;
