import React from "react";
import { Container, Box, Text, Link } from "theme-ui";
import * as Scroll from "react-scroll";

import "./styles/global.css";
import Header from "./components/header";
import Footer from "./components/footer";

export const wrapPageElement = ({ element, props }) => (
  <Box
    {...props}
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }}
  >
   <Link
    href="/book"
    sx={{
      bg: "#14A186",
      padding: "20px 15px",
      textDecoration: "none",
    }}
    >
      <Text
        sx={{
          color: "#fff",
          textAlign: "center",
          letterSpacing: "1px",
          textDecoration: "none",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        THE LAST DAY TO BOOK THE STUDIO IS AUGUST 21ST
      </Text>
    </Link>
    <Box sx={{ display: "flex", flexDirection: "column", flex: "1 0 auto" }}>
      <Scroll.Element name="home">
        <Container
          variant="large"
          sx={{
            flexShrink: 0,
          }}
        >
          <Header />
        </Container>
      </Scroll.Element>
      <Box id="content" sx={{ flex: "1 0 auto" }}>
        {element}
      </Box>
    </Box>
    <Container
      variant="medium"
      sx={{
        flexShrink: 0,
      }}
    >
      <Footer />
    </Container>
  </Box>
);
