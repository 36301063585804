import * as React from "react";

function SvgInstagram({ color = "#000", ...rest }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" {...rest}>
      <path
        d="M9 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.67.31.421.163.72.358 1.036.673.315.315.51.615.673 1.035.123.317.27.794.31 1.671.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.877-.187 1.354-.31 1.671-.163.42-.358.72-.673 1.035-.315.315-.615.51-1.035.673-.317.123-.794.27-1.671.31-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.187-1.67-.31a2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035c-.123-.317-.27-.794-.31-1.671-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.67.163-.421.358-.72.673-1.036.315-.315.615-.51 1.035-.673.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052M9 0C6.556 0 6.25.01 5.29.054 4.33.098 3.676.25 3.104.473A4.412 4.412 0 001.51 1.51c-.5.5-.809 1.002-1.039 1.594C.25 3.677.098 4.33.054 5.289.01 6.25 0 6.556 0 9s.01 2.75.054 3.71c.044.959.196 1.613.418 2.185.23.592.538 1.094 1.039 1.594.5.5 1.002.809 1.594 1.039.572.222 1.226.374 2.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.185-.418a4.412 4.412 0 001.594-1.039c.5-.5.808-1.002 1.038-1.594.223-.572.375-1.226.419-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.185A4.412 4.412 0 0016.49 1.51c-.5-.5-1.002-.808-1.594-1.038C14.323.25 13.67.098 12.711.054 11.75.01 11.444 0 9 0zm0 4.378a4.622 4.622 0 100 9.244 4.622 4.622 0 000-9.244zM9 12a3 3 0 110-6 3 3 0 010 6zm5.884-7.804a1.08 1.08 0 11-2.16 0 1.08 1.08 0 012.16 0z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgInstagram;
