export default {
  breakpoints: ["768px", "1200px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      '"Circular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Bodoni 175", serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 28, 36, 48, 54, 64, 72],
  fontWeights: {
    body: 400,
    bodyMedium: 500,
    heading: 400,
  },
  lineHeights: {
    body: [1.714, 1.714, 1.625],
    heading: 1.125,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#07c",
    secondary: "#30c",
    muted: "#f6f6f6",
  },
  text: {
    default: {
      fontSize: [1, 2, 2],
      fontWeight: "body",
      lineHeight: [1.714, 1.714, 1.625],
      color: "text",
    },
    heading: {
      color: "text",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    h1: {
      fontSize: [5, 6, 7],
      color: "text",
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: [3, 3, 4],
    },
    h2: {
      fontSize: [4, 5, 7],
      color: "text",
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: [3, 3, 4],
    },
    h3: {
      fontSize: [3, 4, 5],
      color: "text",
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: [3, 3, 4],
    },
    h4: {
      fontSize: [2, 3, 4],
      color: "text",
      fontWeight: "bodyMedium",
      lineHeight: "heading",
      marginBottom: [2, 2, 3],
      fontFamily: "body",
    },
    h5: {
      fontSize: [2, 3, 3],
      color: "text",
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 1,
    },
  },
  links: {
    nav: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "text",
      fontSize: "18px",
      lineHeight: "1",
      transition: "all 300ms",
      borderBottom: "1px solid #fff",
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        borderBottom: "1px solid #000",
      },
    },
    "mobile-nav": {
      cursor: "pointer",
      display: "block",
      fontFamily: "heading",
      fontWeight: "heading",
      color: "#fff",
      fontSize: "32px",
      textAlign: "center",
      lineHeight: "1",
      textDecoration: "none",
    },
  },
  images: {
    rounded: {
      borderRadius: 4,
    },
  },
  buttons: {
    primary: {
      background: "#fff",
      color: "text",
      fontFamily: "heading",
      borderRadius: "50px",
      border: "1px solid #000",
      boxShadow: "4px 4px 0 0 #000",
      px: "40px",
      cursor: "pointer",
      transition: "all 300ms",
      transform: "translateY(-2px)",
      "&:hover": {
        transform: "translateY(0)",
        color: "#fff",
        background: "#000",
        boxShadow: "none",
      },
    },
    "primary-inverted": {
      transform: "translateY(-2px)",
      background: "#000",
      color: "#fff",
      fontFamily: "heading",
      borderRadius: "50px",
      border: "1px solid #fff",
      boxShadow: "4px 4px 0 0 #fff",
      px: "40px",
      cursor: "pointer",
      transition: "all 300ms",
      "&:hover": {
        transform: "translateY(0)",
        color: "#000",
        background: "#fff",
        boxShadow: "none",
      },
    },
  },
  layout: {
    container: {
      margin: "0 auto",
    },
    small: {
      margin: "0 auto",
      width: "100%",
      maxWidth: "940px",
    },
    medium: {
      margin: "0 auto",
      width: "100%",
      maxWidth: "1200px",
    },
    large: {
      margin: "0 auto",
      width: "100%",
      maxWidth: "1400px",
    },
  },
  variants: {
    rounded: {
      borderRadius: 4,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [1, 2, 5],
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      width: "15%",
      margin: "0 auto",
    },
  },
};
